import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout/Layout"
import Article from "../../../components/Article/Article"
import CallToAction from "../../../components/Article/CallToAction"
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import A from "../../../components/FastLink/A"
import i18n from "i18next"

const SeoArticle = ({ data }) => {
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  return (
    <Layout
      seo={{
        title: "Czym jest favicon i czy może mieć wpływ na widoczność strony w sieci?",
        description:
          "Dowiedz się, czym są, gdzie się znajdują i jakie właściwości mają favicony, a także sprawdź, jak je stworzyć i czy mają bezpośredni wpływ na działania SEO.",
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/baza-wiedzy/artykuly/",
                name: "Baza wiedzy",
              },
            },
            {
              "@type": "ListItem",
              position: 3,
              item: {
                "@id":
                  "/baza-wiedzy/artykul/favicon-czyli-ikona-na-pasku-adresu-jak-ja-stworzyc-i-czy-ma-wplyw-na-seo/",
                name: "Czym jest favicon i czy może mieć wpływ na widoczność strony w sieci?",
              },
            },
          ],
        },
      }}
    >
      <Breadcrumbs
        path={[
          { url: "/", text: "Główna" },
          {
            url: "/baza-wiedzy/artykuly/",
            text: "Baza wiedzy / Artykuły",
          },
          {
            url: "/baza-wiedzy/artykul/favicon-czyli-ikona-na-pasku-adresu-jak-ja-stworzyc-i-czy-ma-wplyw-na-seo",
            text: "",
          },
        ]}
      />
      <Article
        img={
          <img
            src={data.file.childImageSharp.fluid.src}
            alt={"Favicon, czyli ikona na pasku adresu. Jak ją stworzyć i czy ma wpływ na SEO? "}
          />
        }
      >
        <h1>Favicon, czyli ikona na pasku adresu. Jak ją stworzyć i czy ma wpływ na SEO? </h1>
        <p>
          <strong>
            Drobne i charakterystyczne elementy strony internetowej potrafią przykuć uwagę i zapaść w pamięć
            użytkownikom. Jednym z takich graficznych szczegółów jest favicon, czyli niewielka ikona znajdująca się na
            pasku adresu w przeglądarce. Sprawdź, co warto o niej wiedzieć, jak ją zrobić i czy ma wpływ na
            pozycjonowanie SEO.
          </strong>
        </p>
        <h2>Gdzie znajduje się favicon i jakie ma właściwości? </h2>
        <p>
          Favicon to niewielki, kwadratowy element graficzny o rozmiarach 16x16px lub 32x32px znajdujący się w co
          najmniej trzech lokalizacjach przeglądarki:{" "}
        </p>
        <ul>
          <li>na pasku adresu,</li>
          <li>w zakładce ulubionych,</li>
          <li>na karcie strony internetowej.</li>
        </ul>
        <p className="inserted-seo-link">
          <a
            href="https://blog.wenet.pl/seo-pozycjonowanie/czym-jest-audyt-seo-dlaczego-jest-potrzebny-wlascicielom-stron-www/"
            target="_blank"
            rel="noopener, noreferrer"
          >
            Czym jest audyt SEO
          </a>{" "}
          strony WWW? Sprawdź teraz!
        </p>
        <blockquote>
          Niekiedy favicony pojawiają się również w wynikach wyszukiwania Google lub innych wyszukiwarek, co pozwala
          zwrócić uwagę użytkowników na daną domenę. Jednak najpopularniejsza wyszukiwarka wciąż testuje to rozwiązanie
          i nie podjęła jeszcze ostatecznej decyzji co do wyświetlania się favicon.
        </blockquote>
        <p>
          Mimo niewielkich rozmiarów favicon może stać się charakterystycznym i oryginalnym wyróżnikiem witryny WWW. W
          gąszczu otwartych kart strona posiadająca rzucający się w oczy ozdobnik graficzny przyciąga uwagę i zachęca
          użytkownika do aktywności.{" "}
        </p>
        <p>
          Aby mieć pewność, że favicon będzie wyświetlał się we wszystkich przeglądarkach, należy zadbać o odpowiednie
          rozszerzenie pliku. W tym przypadku najlepszym wyborem będzie: <strong>.ico</strong>, <strong>.png</strong>{" "}
          lub <strong>.gif</strong>.{" "}
        </p>
        <CallToAction
          to={"/"}
          text={"Wykonaj darmowy audyt strony i popraw jej widoczność w wyszukiwarce. Wykonaj teraz >>"}
        />
        <h2>Jak stworzyć własny favicon?</h2>
        <p>Żeby zrobić favicon na swoją stronę internetową, nie potrzeba niebywałych umiejętności. </p>
        <p className="inserted-seo-link">
          Szukasz sposobu na poprawienie widoczności Twojej strony WWW w wyszukiwarce?{" "}
          <a href="/">Wykonaj audyt SEO strony</a>.
        </p>
        <p>
          Do pierwszej metody potrzebny będzie program graficzny, w którym należy otworzyć okno zgodne z wymiarami
          favicony (16x16px lub 32x32px). Później inwencja należy od twórcy. Przestrzeń można wypełnić gotową już
          grafiką (np. logiem firmy, inicjałami, nazwą strony internetowej) lub postąpić spontanicznie i coś narysować.
          Na końcu należy zapisać plik w jednym z trzech rozszerzeń dedykowanym faviconom (<strong>.ico</strong>,{" "}
          <strong>.png</strong> lub <strong>.gif</strong>).
        </p>
        <p>
          Dla tych, którzy chcą ominąć proces instalacji programu graficznego, zostały stworzone specjalne miejsca w
          sieci nazywane: favicon generator. Na takich stronach jak:{" "}
          <A to="https://www.favicon.cc/" text="favicon.cc" /> lub{" "}
          <A to="https://www.degraeve.com/favicon/" text="DeGraeve" /> bez problemu można zrobić własną ikonę, lub
          zaimportować grafikę. Dodatkowo istnieje możliwość sprawdzenia w trakcie pracy, jak ikona będzie wyglądać w
          przeglądarce.{" "}
        </p>
        <h2>Czy favicony wpływają na pozycjonowanie SEO?</h2>
        <p>
          Wprawdzie nie ma potwierdzonych analiz mówiących, że posiadanie favicony wpływa bezpośrednio na
          pozycjonowanie, to obecność tej niewielkiej ikony może działać korzystnie w całym procesie SEO.
        </p>
        <p>
          <strong>Po pierwsze</strong> ze względu na użyteczność. Favicony ułatwiają identyfikację strony, co ma pewien
          wpływ na jej rozpoznawalność i użyteczność. Wystarczy, że użytkownik będzie kojarzył ikonę z danym serwisem,
          dzięki czemu sprawniej odnajdzie ją w wynikach wyszukiwania lub w folderze zakładek. A jak wiadomo,
          użyteczność to jeden z czynników mających pozytywny wpływ na pozycjonowanie SEO. Mówimy tutaj o znikomym
          wpływie, jednak warto to odnotować.{" "}
        </p>
        <p>
          <strong>Po drugie</strong> ze względu na obecność w zakładkach. Można przypuszczać, że strony internetowe
          mające favicony częściej znajdują się w zakładkach przeglądarki. A takie wyszukiwarki jak Google dysponują
          swoimi przeglądarkami i faworyzują adresy stron WWW, które trafiają do folderu „ulubione”.
        </p>
        <p>
          <strong>Po trzecie</strong> ze względu na SXO, czyli doświadczenia użytkownika z poziomu wyszukiwarki. Jeśli
          ikona pojawia się w SERP (lista adresów znaleziona przy pomocy wyszukiwarki), to przyciąga wzrok i może
          pozytywnie wpływać na współczynnik CTR (współczynnik klikalności), co w praktyce oznacza więcej kliknięć z
          poziomu wyszukiwarki, a co za tym idzie – więcej odwiedzin na stronie.
        </p>
        <p>
          Powyższe spostrzeżenia mogą wydawać się subtelne, jednak jedno jest pewne: favicony na pewno nie
          przeszkadzają, a prawdopodobnie wspierają działania SEO.{" "}
        </p>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "img/full/favicon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
  }
`

export default SeoArticle
